<template>
  <div id="app">
    <router-view />
    <div class="signature">
      Copyright © www.jxhmi.cn, All Rights Reserved.
      一站式物联网人机交互解决方案
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.checkBrowser();
  },
  methods: {
    checkBrowser() {
      var theUA = window.navigator.userAgent.toLowerCase();
      console.log(theUA);
      if (
        (theUA.match(/msie\s\d+/) && theUA.match(/msie\s\d+/)[0]) ||
        (theUA.match(/trident\/?\d+/) && theUA.match(/trident\/?\d+/)[0])
      ) {
        var ieVersion =
          (theUA.match(/msie\s\d+/) &&
            theUA.match(/msie\s\d+/)[0].match(/\d+/)[0]) ||
          (theUA.match(/trident\/?\d+/) &&
            theUA.match(/trident\/?\d+/)[0].match(/\d+/)[0]);
        if (ieVersion < 11) {
          var str = "暂不支持较低版本的IE浏览器";
          var str2 =
            "推荐使用:<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>谷歌</a>、" +
            "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%81%AB%E7%8B%90%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:blue;'>火狐</a>" +
            "或其他双核极速模式";
          document.writeln(
            "<pre style='text-align:center; height:100%;border:0;position:fixed;top:0;left:0;width:100%;z-index:1234'>" +
              "<h2 style='padding-top:200px;margin:0'><strong>" +
              str +
              "<br/></strong></h2><h2>" +
              str2 +
              "</h2><h2 style='margin:0'><strong>如果你的使用的是双核浏览器,请切换到极速模式访问<br/></strong></h2></pre>"
          );
          document.execCommand("Stop");
        }
      }
    },
  },
};
</script>

<style>
@import "assets/css/base.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303133;

  background: #2f3447;
}

.signature {
  margin: 10px auto;

  font-size: 12px;
  color: #fff;
  line-height: 20px;
}
</style>
